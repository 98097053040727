import React from 'react';
import Title from "../Common/Title";

const ServicesInfo = () => (
  <div className="panel my-5">
    <section className="current services-info">
      <div className="container">
        <div className="row text-center">
          <div className="col-sm-12 col-md-12 col-md-12">
            <Title>Послуги</Title>
            <p  className="text-start"> &nbsp;&nbsp;
              За роки існування медичної установи впроваджено <span className="highlight">нові методики та новітні
              технології</span> у сфері лазерної медицини за основними напрямками:
            </p>
            <ul className="text-start with-bullets">
              <li><span className="highlight">Хірургія</span> (варикозна хвороба нижніх кінцівок, геморої, контрактура Дюпюїтрена, кістозні новоутворення
                шкіри та підшкірної клітковини), </li>
              <li><span className="highlight">Дерматологія</span> (новоутворення шкіри),</li>
              <li><span className="highlight">Гінекологія</span> (гістероскопія, гістерорезекція, інтимна контурна пластика та технологія
                фракційного фототермолізу). </li>
              <li>Одним із важливих напрямків роботи медичної установи є &nbsp;
                <span className="highlight">Пластична Хірургія.</span>
              </li>
              {/*<li>Також в нас відкрився новий напрямок &nbsp;*/}
              {/*  <span className="highlight">Проктологія.</span>*/}
              {/*</li>*/}
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default ServicesInfo;